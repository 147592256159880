import React from 'react';
import weddingPic from "./Categories/1 Wedding.png"
import dreamHousePic from "./Categories/2 Dream House.png"
import dreamCar from "./Categories/3 Dream Car.png"
import educationPic from "./Categories/4 Higher Education.png"
import retirePic from "./Categories/5 Retirement.png"
import overseas from "./Categories/6 Overseas Migration.png"
import travelPic from "./Categories/7 Travel and Vacation.png"
import business from "./Categories/8 Starting a Business.png"
import healthPic from "./Categories/9 Health and Fitness.png"

const CategorySelection = ({ categories, onSelectCategory }) => {

  const categoryImages = {
    'Wedding': weddingPic,
    'Buy a House': dreamHousePic,
    'Buy a Car': dreamCar,
    'Higher Education': educationPic,
    'Retirement' : retirePic,
    'Migrate' : overseas,
    'Traveling' :  travelPic,
    'Starting a Business' :  business,
    'Getting Healthy' : healthPic,
  
  };


  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl ml-44">
      <h2 className="text-xl font-bold mb-4">உங்கள் இலக்கை தேர்வு செய்யவும்</h2>
      <div className="grid grid-cols-5 gap-3 ">
        {categories.map((category) => (
          <div 
          key={category} 
          className="text-center border shadow-md hover:bg-gray-100 cursor-pointer " 
          onClick={() => onSelectCategory(category)}
        >
          <img
            src={categoryImages[category]}
            alt={category}
            className="w-20 h-20 object-cover rounded-lg mb-2 mx-auto mt-3"
          />
          <button className="w-full text-center p-4   ">
            {category}
          </button>
        </div>
        
        ))}
      </div>
    </div>
  );
};

export default CategorySelection;
